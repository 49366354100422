import React from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { LanguageMarkup } from "./LanguageMarkup";
import { FaNodeJs, FaPython, FaPhp } from "react-icons/fa6";
import { AiOutlineCopy, AiOutlineSelect } from "react-icons/ai";
import { handleCopy } from "../../common/common";

export const CodeSample = () => {
  return (
    <Tabs defaultActiveKey="url" className="mb-3">
      <Tab eventKey="url" title="URL">
        <LanguageMarkup
          language="url"
          value={`https://api.ourdomain.com/request?api_key=FFE29732037C40108DF19A879212024A&amazon_domain=amazon.com&asin=B073JYC4XM&type=product;`}
        />
        <div className="d-flex align-items-center gap-3">
          <Button
            variant="link"
            className="text-decoration-none color-inherit p-0"
            onClick={() => handleCopy("url")}
          >
            <AiOutlineCopy className="me-1" />
            <span>Copy</span>
          </Button>
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none color-inherit"
          >
            <AiOutlineSelect className="me-1" />
            <span>Open in new Tab</span>
          </a>
        </div>
      </Tab>
      <Tab eventKey="curl" title="Curl">
        <LanguageMarkup
          language="curl"
          value={`curl -L --get https://api.ourdomain.com/request \n
    -d api_key="FFE29732037C40108DF19A879212024A" \n
    -d amazon_domain="amazon.com" \n
    -d asin="B073JYC4XM" \n
    -d type="product"`}
        />
        <Button
          variant="link"
          className="text-decoration-none color-inherit p-0"
          onClick={() => handleCopy("curl")}
        >
          <AiOutlineCopy className="me-1" />
          <span>Copy</span>
        </Button>
      </Tab>
      <Tab
        eventKey="node"
        title={
          <>
            <FaNodeJs />
            <span className="ms-1">Node.JS</span>
          </>
        }
      >
        <LanguageMarkup
          language="js"
          value={`const axios = require('axios');

// set up the request parameters
const params = {
  api_key: "FFE29732037C40108DF19A879212024A",
  amazon_domain: "amazon.com",
  asin: "B073JYC4XM",
  type: "product"
}

// make the http GET request to Rainforest API
axios.get('https://api.ourdomain.com/request', { params })
.then(response => {
    // print the JSON response from Rainforest API
    console.log(JSON.stringify(response.data, 0, 2));
}).catch(error => {
    // catch and print the error
    console.log(error);
})`}
        />
        <Button
          variant="link"
          className="text-decoration-none color-inherit p-0"
          onClick={() => handleCopy("curl")}
        >
          <AiOutlineCopy className="me-1" />
          <span>Copy</span>
        </Button>
      </Tab>
      <Tab
        eventKey="python"
        title={
          <>
            <FaPython />
            <span className="ms-1">Python</span>
          </>
        }
      >
        <LanguageMarkup
          language="python"
          value={`# Hello world in Python 2
print "Hello World"

# Hello world in Python 3 (aka Python 3000)
print("Hello World")`}
        />
        <Button
          variant="link"
          className="text-decoration-none color-inherit p-0"
          onClick={() => handleCopy("curl")}
        >
          <AiOutlineCopy className="me-1" />
          <span>Copy</span>
        </Button>
      </Tab>
      <Tab
        eventKey="php"
        title={
          <>
            <FaPhp />
            <span className="ms-1">PHP</span>
          </>
        }
      >
        <LanguageMarkup
          language="php"
          value={`<?php
  echo "Hello World!";
?>`}
        />
        <Button
          variant="link"
          className="text-decoration-none color-inherit p-0"
          onClick={() => handleCopy("curl")}
        >
          <AiOutlineCopy className="me-1" />
          <span>Copy</span>
        </Button>
      </Tab>
    </Tabs>
  );
};
