import { useFormik } from "formik";
import { AuthWrapper } from "../../../components/authentication/AuthWrapper"
import {
    FloatingLabel,
    Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
    const validate = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validate,
        onSubmit: (v) => alert(JSON.stringify(v, null, 2)),
    });
    return (
        <AuthWrapper title='Forgot Password' buttonText='Send Password Reset Link' formik={formik}>
            <FloatingLabel label="Email address" className="mb-3">
                <Form.Control
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    required
                    placeholder=""
                />
                {formik.touched.email && formik.errors.email && <span className="text-danger font-14">{formik.errors.email}</span>}
            </FloatingLabel>
            <p className="mt-3 text-end">
                <Link to="/sign-in">
                    Back to Login
                </Link>
            </p>
        </AuthWrapper>
    )
}
