import PostRequest from "../../services/PostRequest";

export const ZIPCODES_LIST = "SELECT_CHARACTER";

export const setZipCodeList = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/zipcodes/get-zipcodes/");

      dispatch({
        type: ZIPCODES_LIST,
        value: response,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const setZipCodeSearch = (search) => {
  return async (dispatch) => {
    try {
      const data = {
        search: search,
      };

      const response = await PostRequest("/zipcodes/search-zipcodes/", data);

      dispatch({
        type: ZIPCODES_LIST,
        value: response,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};
