import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-email/dist/style.css";

import { AppRoutes } from "./components/AppRoutes";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { zipCodeReducer } from "./reducer/zipCode/ZipCode.reducer";
import { HeaderContextProvider } from "./contexts/HeaderContext";
import { collectionReducer } from "./reducer/collection/Collection.reducer";
import { defaultReducer } from "./reducer/default/default.reducer";
import { planPaymentReducer } from "./reducer/plan-payment/palanPayment.reducer";

export default function App() {
  const [accountOpen, setAccountOpen] = useState(false);

  const location = useLocation();

  const isAuthRoute = [
    "/sign-in",
    "/sign-up",
    "/password/forgot",
    "/password/reset",
  ].includes(location.pathname);

  if (isAuthRoute) return <AppRoutes />;

  const rootReducer = combineReducers({
    zipCodeReducer: zipCodeReducer,
    collectionReducer: collectionReducer,
    defaultReducer: defaultReducer,
    planPaymentReducer: planPaymentReducer,
  });

  const myStore = configureStore({
    reducer: rootReducer,
  });

  if (isAuthRoute) return <AppRoutes />;

  return (
    <Provider store={myStore}>
      <HeaderContextProvider>
        <AppRoutes />
        <ToastContainer />
      </HeaderContextProvider>
    </Provider>
  );
}
