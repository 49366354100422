import { ASIN_OPEN, ASIN_VIEW_DETAILS } from "./default.action";

const intailreducer = {
  asinBar: false,
  asinDetails: false,
};

export const defaultReducer = (state = intailreducer, action) => {
  switch (action.type) {
    case ASIN_OPEN:
      return { ...state, asinBar: action.value };

    case ASIN_VIEW_DETAILS:
      return { ...state, asinDetails: action.value };

    default:
      return state;
  }
};
