import {
  Button,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  AiOutlineReload,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlinePushpin,
  AiOutlineFilter,
} from "react-icons/ai";
import { PostCodesTable } from "../../components/postcodes/Table";
import { useEffect, useState } from "react";
import { AddPostCodes } from "../../components/postcodes/AddPostCodes";
import { Default } from "../../components/default/Default";
import Select from "react-select";
import { amazonDomainOptions } from "../../data/constants";
import { CountryOption } from "../../common/custom-options";
import { POSTCODES_DATA as data } from "../../data/constants";
import { FeatureDashboard } from "../../components/FeatureDashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  setZipCodeList,
  setZipCodeSearch,
} from "../../reducer/zipCode/ZipCode.action";
import PostRequest from "../../services/PostRequest";
var searchTime;
const selectStyles = {
  control: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const POSTCODE_FEATURES = [
  { title: `Add zip/postal codes for any domain` },
  {
    title: (
      <>
        Specify your zipcode / postal code in the <code>customer_zipcode</code>{" "}
        <a
          href="https://www.google.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none color-inherit"
        >
          request parameter
        </a>
      </>
    ),
  },
  {
    title: `Your request will be localized to the zipcode or postal code selected`,
  },
  {
    title: (
      <>
        Perfect to retrieving regional data such as Amazon Fresh -{" "}
        <a
          href="https://www.google.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none color-inherit"
        >
          learn more
        </a>
      </>
    ),
  },
];

export const PostCodes = () => {
  const dispatch = useDispatch();
  const zipCodesList = useSelector((item) => item.zipCodeReducer.zipCodesList);

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handelRefreshZipCodeList = async () => {
    try {
      setLoader(true);
      await dispatch(setZipCodeList());
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handelSearchZipCode = async (e) => {
    if (searchTime) {
      clearTimeout(searchTime);
    }

    searchTime = setTimeout(() => {
      dispatch(setZipCodeSearch(e.target.value));
    }, 400);
  };

  // const loadZipCodes = () => {
  //   dispatch(setZipCodeList());
  // };

  // useEffect(() => {
  //   loadZipCodes();
  // }, []);

  return (
    <Default>
      <div className="p-3 bg-info bg-opacity-25 d-md-flex align-items-center justify-content-between">
        {zipCodesList && (
          <div>
            {zipCodesList.length}{" "}
            {zipCodesList.length > 1 ? "Zipcodes" : "Zipcode"}
          </div>
        )}
        <div className="d-md-flex flex-wrap">
          <OverlayTrigger
            overlay={<Tooltip>Refresh the table to see latest status</Tooltip>}
          >
            <Button
              onClick={handelRefreshZipCodeList}
              variant="link"
              className="text-decoration-none"
            >
              <AiOutlineReload
                className={`${loader ? "loader-animation" : ""}`}
              />
              <span className="ms-1">Refresh</span>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                You can have up to 1 zip or postal codes set up on your account
                at any one time (0 left).
              </Tooltip>
            }
          >
            <Button
              onClick={handleShow}
              variant="link"
              className="text-decoration-none"
            >
              <AiOutlinePlus />
              <span className="ms-1">Add New</span>
            </Button>
          </OverlayTrigger>
          <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
            <Dropdown.Toggle
              variant="link"
              className="text-decoration-none hide__dropdown-icon"
            >
              <AiOutlineFilter />
              <span className="ms-1">
                {value && value?.value !== "all" ? value.value : "Filter"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="font-14"
              style={{ zIndex: 1021, minWidth: "11rem" }}
            >
              <Dropdown.Item
                as="button"
                value="all"
                onClick={(e) => setValue({ value: e.target.value })}
              >
                Show All ZipCodes
              </Dropdown.Item>
              <Select
                autoFocus
                menuIsOpen
                onChange={(newValue) => {
                  setValue(newValue);
                  setIsOpen(false);
                }}
                options={amazonDomainOptions}
                getOptionLabel={(option) => <CountryOption data={option} />}
                styles={selectStyles}
                value={value}
                onMenuClose={() => setIsOpen(false)}
                menuPlacement="auto"
              />
            </Dropdown.Menu>
          </Dropdown>
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              onChange={handelSearchZipCode}
              aria-describedby="basic-addon2"
            />
            <Button>
              <AiOutlineSearch />
            </Button>
          </InputGroup>
        </div>
      </div>

      <PostCodesTable />

      {/* <FeatureDashboard
          icon={AiOutlinePushpin}
          title="Zipcodes & Postal Codes"
          description="Add zipcodes or postal codes for a given Amazon domain and select them when running API requests to get localized results."
          buttonText="Add Zipcode"
          features={POSTCODE_FEATURES}
          handleShow={handleShow}
        /> */}
      <AddPostCodes show={show} handleClose={handleClose} />
    </Default>
  );
};
