import { Badge, Button, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import { monthOptions } from "../../data/constants";
import { useState } from "react";
import moment from "moment";

export const Invoices = ({ show, handleClose }) => {
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0].value);

  return (
    <Offcanvas placement="end" show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Invoices</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Select
          options={monthOptions}
          defaultValue={monthOptions[0]}
          menuPlacement="auto"
          onChange={(month) => setSelectedMonth(month.value)}
          className="mb-3"
        />
        <Button size="sm">
          Download all {moment(selectedMonth).format("MMMM")} invoices as a ZIP
          file
        </Button>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <p className="mb-0">
              $15.00{" "}
              <span className="text-muted font-14">
                on 09/20/2024 at 01:16 AM
              </span>
            </p>
            <a href="!#">Download PDF</a>
          </div>
          <Badge bg="success">PLAN</Badge>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <p className="mb-0">
              $15.00{" "}
              <span className="text-muted font-14">
                on 09/20/2024 at 01:16 AM
              </span>
            </p>
            <a href="!#">Download PDF</a>
          </div>
          <Badge bg="success">PLAN</Badge>
        </div>
        <div className="text-center">No Invoices Found</div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
