import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "../../config/Api_url";

const style = { layout: "vertical" };

const ButtonWrapper = ({ showSpinner, onApprove, createOrder }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
        <>
            {showSpinner && isPending && <div className="spinner-grow" />}
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[style]}
                fundingSource={undefined}
                createOrder={createOrder}
                onApprove={onApprove}
            />
        </>
    );
};

export const PaypalPayment = ({ onApprove, createOrder }) => {
    return (
        <PayPalScriptProvider
            options={{
                clientId: PAYPAL_CLIENT_ID || 'test',
                components: "buttons",
                currency: "USD",
            }}
        >
            <ButtonWrapper
                showSpinner={false}
                createOrder={createOrder}
                onApprove={onApprove}
            />
        </PayPalScriptProvider>
    );
};
