import { useRef } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AiOutlineCopy } from "react-icons/ai";
import { IoHelpCircleOutline } from "react-icons/io5";
import { handleCopy } from "../../common/common";
import moment from "moment";

export const AboutYou = ({
  errors,
  setErrors,
  userProfile,
  accountDetails,
}) => {
  const apiKeyRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`,
      }));
    } else if (
      name === "email" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: `Email is invalid!`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4} md={12} lg={4}>
          Name
        </Form.Label>
        <Col sm={8} md={12} lg={8}>
          <Form.Control
            name="name"
            type="text"
            placeholder="Name"
            defaultValue={userProfile?.name}
            onChange={handleChange}
            required
          />
          {errors.name && (
            <span className="font-12 text-danger">{errors.name}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={4} md={12} lg={4}>
          API Key
        </Col>
        <Col sm={8} md={12} lg={8} className="d-flex align-items-center">
          <p ref={apiKeyRef} className="me-2 mb-0 text-break text-truncate">
            {accountDetails?.api_key}
          </p>
          <AiOutlineCopy
            onClick={() => handleCopy(apiKeyRef.current.innerText)}
            className="cursor-pointer"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4} md={12} lg={4}>
          Email
        </Form.Label>
        <Col sm={8} md={12} lg={8}>
          <Form.Control
            name="email"
            type="email"
            defaultValue={userProfile?.email}
            placeholder="Email"
            onChange={handleChange}
            required
          />
          {errors.email && (
            <span className="font-12 text-danger">{errors.email}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={4} md={12} lg={4}>
          Joined
        </Col>
        <Col sm={8} md={12} lg={8}>
          {moment(userProfile?.created_at).format("DD/MM/YYYY")}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={4} md={12} lg={4}>
          <span>System Emails</span>
          <OverlayTrigger
            overlay={
              <Tooltip>
                System emails include warnings when you're about to exceed your
                credit usage or use overage. We recommend keeping these enabled
                at all times.
              </Tooltip>
            }
          >
            <span>
              <IoHelpCircleOutline
                name="system_emails"
                size={20}
                className="cursor-pointer"
              />
            </span>
          </OverlayTrigger>
        </Col>
        <Col sm={8} md={12} lg={8}>
          <Form.Check
            defaultChecked={accountDetails?.system_emails}
            name="system_emails"
            type="switch"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={4} md={12} lg={4}>
          <span>Update Emails</span>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Update emails updates on our products, service enhancements and
                offers. We recommend keeping these enabled to ensure you're
                updated about upcoming changes and improvements.
              </Tooltip>
            }
          >
            <span>
              <IoHelpCircleOutline
                name="update_emails"
                size={20}
                className="cursor-pointer"
              />
            </span>
          </OverlayTrigger>
        </Col>
        <Col sm={8} md={12} lg={8}>
          <Form.Check
            defaultChecked={accountDetails?.update_emails}
            name="update_emails"
            type="switch"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={4} md={12} lg={4}>
          <span>Error Notification Emails</span>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Receive email notifications when requests fail and new entries
                are put in the Error Log.
              </Tooltip>
            }
          >
            <span>
              <IoHelpCircleOutline
                name="notification_emails"
                size={20}
                className="cursor-pointer"
              />
            </span>
          </OverlayTrigger>
        </Col>
        <Col sm={8} md={12} lg={8}>
          <Form.Check
            defaultChecked={accountDetails?.notification_emails}
            name="notification_emails"
            type="switch"
          />
        </Col>
      </Form.Group>
    </>
  );
};
