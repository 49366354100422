import { Fragment } from "react";
import PlaceholderLoading from "react-placeholder-loading";

export const ZipCodeLoader = () => {
  return (
    <Fragment>
      {Array.from({ length: 2 }).map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <PlaceholderLoading shape="rect" width={18} height={18} />
            </td>
            <td>
              <PlaceholderLoading shape="rect" width={150} height={22} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={100} height={22} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>
            <td>
              <PlaceholderLoading shape="rect" width={120} height={30} />
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export const CollectionLoader = () => {
  return (
    <Fragment>
      {Array.from({ length: 2 }).map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <PlaceholderLoading shape="rect" width={100} height={22} />
            </td>
            <td>
              <PlaceholderLoading shape="rect" width={150} height={22} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={150} height={22} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={100} height={22} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={100} height={22} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>
            <td>
              <PlaceholderLoading shape="rect" width={120} height={30} />
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};
