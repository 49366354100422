import { createContext, useContext, useState } from "react";

export const HeaderContext = createContext();

export const HeaderContextProvider = ({ children }) => {
    const [showCollection, setShowCollection] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);

    const handleShowCollection = () => setShowCollection(true);
    const handleCloseCollection = () => setShowCollection(false);

    return (
        <HeaderContext.Provider
            value={{
                showCollection,
                handleShowCollection,
                handleCloseCollection,
                accountOpen,
                setAccountOpen,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};

export const useHeader = () => useContext(HeaderContext);
