// import { useState } from 'react';
// import { Collapsed } from './Collapsed';
import { Expanded } from './Expanded';

export const Sidebar = () => {
    // const [isExpanded, setIsExpanded] = useState(true);

    return <Expanded />

    // return (
    //     <>
    //         {isExpanded ? <Expanded setIsExpanded={setIsExpanded} /> : <Collapsed setIsExpanded={setIsExpanded} />}
    //     </>
    // )
}
