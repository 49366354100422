import { AddCollection } from "../../components/collections/AddCollection";
import { useHeader } from "../../contexts/HeaderContext";
import { Default } from "../../components/default/Default";
import { CollectionsTable } from "../../components/collections/Table";
import { COLLECTIONS_DATA as data } from "../../data/constants";
import "./collections.css";
import { FeatureDashboard } from "../../components/FeatureDashboard";
import { AiOutlineDatabase } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setCollectionsList } from "../../reducer/collection/Collection.action";

export const Collections = () => {
  const dispatch = useDispatch();

  const COLLECTION_FEATURES = [
    { title: `Add up to 15,000 Requests per Collection` },
    {
      title: `Schedule the Collection to run monthly, weekly, daily, hourly, per-minute, or manually on-demand`,
    },
    {
      title: (
        <>
          Get results as JSON, CSV or via the{" "}
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none color-inherit"
          >
            Collections API
          </a>
        </>
      ),
    },
    {
      title: (
        <>
          Get notified of new results via email or{" "}
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none color-inherit"
          >
            webhook
          </a>{" "}
          POST
        </>
      ),
    },
    {
      title: (
        <>
          Use{" "}
          <Link
            to="/destinations"
            className="text-decoration-none color-inherit"
          >
            Destinations
          </Link>{" "}
          to upload Collection results to Amazon S3 Buckets, Google Cloud
          Storage buckets, Microsoft Azure Blob Storage, or Alibaba Cloud OSS
          Buckets
        </>
      ),
    },
  ];

  const { showCollection, handleShowCollection, handleCloseCollection } =
    useHeader();

  // const loadCollections = () => {
  //   try {
  //     dispatch(setCollectionsList());
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   loadCollections();
  // }, []);

  return (
    <Default>
      <CollectionsTable />

      {/* <FeatureDashboard
          icon={AiOutlineDatabase}
          title="Collections"
          description="Collections allow you to run Rainforest API requests en masse on our infrastructure."
          buttonText="Add Collection"
          features={COLLECTION_FEATURES}
          handleShow={handleShowCollection}
        /> */}
      <AddCollection
        show={showCollection}
        handleClose={handleCloseCollection}
      />
    </Default>
  );
};
