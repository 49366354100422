import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoEye, IoEyeOff } from "react-icons/io5";

export const ChangePassword = ({ errors, setErrors }) => {
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const spacedStr = name.replace(/([A-Z])/g, " $1");
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${
          spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1)
        } is required.`,
      }));
    } else if (name === "new_password" && value.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        new_password: `New Password must be at least 8 characters long.`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Current Password
        </Form.Label>
        <Col sm={8} className="position-relative">
          <Form.Control
            type={currentPasswordVisible ? "text" : "password"}
            name="old_password"
            placeholder="Current Password"
            onChange={handleChange}
            required
          />
          <span
            onClick={toggleCurrentPasswordVisibility}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "24px",
              top: "8px",
            }}
          >
            {currentPasswordVisible ? <IoEye /> : <IoEyeOff />}
          </span>
          {errors.old_password && (
            <span className="font-12 text-danger">{errors.old_password}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          New Password
        </Form.Label>
        <Col sm={8} className="position-relative">
          <Form.Control
            type={newPasswordVisible ? "text" : "password"}
            name="new_password"
            placeholder="New Password"
            onChange={handleChange}
            required
          />
          <span
            onClick={toggleNewPasswordVisibility}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "24px",
              top: "8px",
            }}
          >
            {newPasswordVisible ? <IoEye /> : <IoEyeOff />}
          </span>
          {errors.new_password && (
            <span className="font-12 text-danger">{errors.new_password}</span>
          )}
        </Col>
      </Form.Group>
    </>
  );
};
