import { useFormik } from "formik";
import { AuthWrapper } from "../../../components/authentication/AuthWrapper";
import { FloatingLabel, Form } from "react-bootstrap";
import PostRequest from "../../../services/PostRequest";
import { showToaster } from "../../../common/common";

export const ResetPassword = (data) => {
  const handlePasswordReset = async () => {
    try {
      const option = {
        showLoader: "reset-id",
        auth: false,
      };

      const response = await PostRequest("/users/register", data, option);

      showToaster(response.data, "success");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Password Required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password Required";
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "Passwords must match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    // onSubmit: (v) => alert(JSON.stringify(v, null, 2)),
    onSubmit: handlePasswordReset,
  });
  return (
    <AuthWrapper
      title="Reset Password"
      buttonText="Reset Password"
      formik={formik}
      loaderId="reset-id"
    >
      <FloatingLabel className="mb-3" label="New Password">
        <Form.Control
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder=""
          required
        />
        {formik.touched.password && formik.errors.password && (
          <span className="text-danger font-14">{formik.errors.password}</span>
        )}
      </FloatingLabel>
      <FloatingLabel className="mb-3" label="Confirm Password">
        <Form.Control
          type="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder=""
          required
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <span className="text-danger font-14">
            {formik.errors.confirmPassword}
          </span>
        )}
      </FloatingLabel>
    </AuthWrapper>
  );
};
