// import { useMemo } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IoHelpCircleOutline } from "react-icons/io5";
import Select from "react-select";
// import languages from "language-list";
// import { codes } from "currency-codes";
// import countryList from "react-select-country-list";
import {
  amazonDomainOptions,
  includeOptions,
  outputOptions,
  // skipGTINCacheOptions,
  // typeOptions,
} from "../data/constants";
import { ChooseAPI, CountryOption } from "../common/custom-options";
import { useSelector } from "react-redux";
import { GenerateKey } from "../common/common";
// import { AiOutlineSelect } from "react-icons/ai";

export const APIRequestFields = ({ isCustomID = false, _asinDetails }) => {
  // const _asinDetails = useSelector((item) => item.defaultReducer.asinDetails);

  // console.log("_asinDetails", _asinDetails);

  // const countryOptions = useMemo(() => countryList().getData(), []);
  // const currencyOptions = codes().map((code) => ({
  //     value: code,
  //     label: code.toLowerCase(),
  // }));
  // const languageOptions = languages()
  //     .getData()
  //     .map((data) => ({
  //         value: data.code,
  //         label: data.language,
  //     }));

  return (
    <Row className="gy-3 mb-3">
      {/* <Col sm={12}>
                <Form.Group>
                    <Form.Label>
                        <span>Type</span>
                        <OverlayTrigger overlay={<Tooltip>Type</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select
                        options={typeOptions}
                        menuPlacement="auto"
                        getOptionLabel={(option) => <ChooseAPI data={option} />}
                    />
                    <a href="https://www.google.com" target='_blank' rel="noopener noreferrer" className='text-decoration-none color-inherit font-14'>
                        <AiOutlineSelect className='me-1' />
                        <span>Product request docs</span>
                    </a>
                </Form.Group>
            </Col> */}
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>Amazon Domain</span>
            <OverlayTrigger overlay={<Tooltip>Amazon Domain</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            options={amazonDomainOptions}
            // defaultValue={
            //   _asinDetails
            //     ? _asinDetails?.amazon_domain
            //     : amazonDomainOptions[1]
            // }
            defaultValue={amazonDomainOptions.find(
              (option) =>
                option.value ===
                (_asinDetails?.amazon_domain || amazonDomainOptions[1]?.value)
            )}
            name="amazon_domain"
            isClearable
            getOptionLabel={(option) => <CountryOption data={option} />}
            menuPlacement="auto"
          />
        </Form.Group>
      </Col>
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Url</span>
                        <OverlayTrigger overlay={<Tooltip>Url</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" />
                </Form.Group>
            </Col> */}
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>ASIN</span>
            <OverlayTrigger overlay={<Tooltip>ASIN</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            key={GenerateKey(10)}
            type="text"
            defaultValue={_asinDetails ? _asinDetails?.asin : ""}
            name="asin"
          />
        </Form.Group>
      </Col>
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>GTIN</span>
                        <OverlayTrigger overlay={<Tooltip>GTIN</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" />
                </Form.Group>
            </Col> */}
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Skip GTIN Cache</span>
                        <OverlayTrigger overlay={<Tooltip>Skip GTIN Cache</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select options={skipGTINCacheOptions} menuPlacement="auto" />
                </Form.Group>
            </Col> */}
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Summarization Attributes</span>
                        <OverlayTrigger
                            overlay={<Tooltip>Summarization Attributes</Tooltip>}
                        >
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select options={includeOptions} menuPlacement="auto" />
                </Form.Group>
            </Col> */}
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Include A Plus Body</span>
                        <OverlayTrigger overlay={<Tooltip>Include A Plus Body</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select options={includeOptions} menuPlacement="auto" />
                </Form.Group>
            </Col> */}
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Language</span>
                        <OverlayTrigger overlay={<Tooltip>Language</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select options={languageOptions} menuPlacement="auto" />
                </Form.Group>
            </Col> */}
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Currency</span>
                        <OverlayTrigger overlay={<Tooltip>Currency</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select options={currencyOptions} menuPlacement="auto" />
                </Form.Group>
            </Col> */}
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Customer Location</span>
                        <OverlayTrigger overlay={<Tooltip>Customer Location</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Select
                        options={countryOptions}
                        menuPlacement="auto"
                        getOptionLabel={(option) => <CountryOption data={option} />}
                    />
                </Form.Group>
            </Col> */}
      <Col sm={12}>
        <Form.Group>
          <Form.Label>
            <span>Customer Postcode/Zipcode</span>
            <OverlayTrigger
              overlay={<Tooltip>Customer Postcode/Zipcode</Tooltip>}
            >
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            menuPlacement="auto"
            isClearable
            isSearchable
            defaultValue={
              _asinDetails
                ? {
                    value: _asinDetails.customer_postalcode,
                    label: _asinDetails.customer_postalcode,
                  }
                : null
            }
            options={[
              { value: "10101010", label: "10101010" },
              { value: "101010", label: "101010" },
              { value: "1010", label: "1010" },
              { value: "1", label: "1" },
            ]}
            name="customer_postcode"
            noOptionsMessage={() =>
              "No customer zipcodes have been set up for amazon.com.au"
            }
          />
        </Form.Group>
      </Col>
      {/* <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        <span>Associate Id</span>
                        <OverlayTrigger overlay={<Tooltip>Associate Id</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" />
                </Form.Group>
            </Col> */}
      <Col sm={12}>Output</Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>Output</span>
            <OverlayTrigger overlay={<Tooltip>Output</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            options={outputOptions}
            menuPlacement="auto"
            defaultValue={
              outputOptions.find(
                (option) => option.value === _asinDetails?.output_enum
              ) || null
            }
            name="output"
            getOptionLabel={(option) => <ChooseAPI data={option} />}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>Include Raw HTML</span>
            <OverlayTrigger overlay={<Tooltip>Include Raw HTML</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            options={includeOptions}
            defaultValue={
              includeOptions.find(
                (option) => option.value === _asinDetails?.include_row_html
              ) || null
            }
            menuPlacement="auto"
            name="include_raw_html"
          />
        </Form.Group>
      </Col>
      {/* <Col sm={12}>
                <Form.Group>
                    <Form.Label>
                        <span>Cookie</span>
                        <OverlayTrigger overlay={<Tooltip>Cookie</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={20} className="cursor-pointer" />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" />
                </Form.Group>
            </Col> */}
      {isCustomID && (
        <Col sm={12}>
          <Form.Group>
            <Form.Label>
              <span>Custom ID</span>
              <OverlayTrigger overlay={<Tooltip>Custom ID</Tooltip>}>
                <span>
                  <IoHelpCircleOutline size={20} className="cursor-pointer" />
                </span>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              key={GenerateKey(10)}
              defaultValue={_asinDetails ? _asinDetails?.custom_id : ""}
              type="text"
              name="custom_id"
            />
          </Form.Group>
        </Col>
      )}
    </Row>
  );
};
