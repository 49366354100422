import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import DatePicker from "react-datepicker";
import "./usage.css";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import PostRequest from "../../services/PostRequest";
import { Default } from "../../components/default/Default";

const generateMonthlyData = () => {
  const data = [];
  for (var day = 1; day <= 30; day++) {
    data.push({
      date: `${day}${getDaySuffix(day)}`,
      uv: Math.floor(Math.random() * 5000) + 500, // Random value between 500 and 5500
    });
  }
  return data;
};

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const renderMonthContent = (month, shortMonth, longMonth, day) => (
  <span>{shortMonth}</span>
);

generateMonthlyData();

export const Usage = () => {
  const [selected, setSelected] = useState(new Date());

  const [userGroupByDate, setUserGroupByDate] = useState([]);

  const loadGroupByDate = async () => {
    try {
      const response = await PostRequest("/users/uses/group-by-date");
      setUserGroupByDate(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    loadGroupByDate();
  }, []);

  return (
    <Default>
      <Row>
        <Col md={6}>
          <span className="fw-bold">{userGroupByDate.length}</span> Requests
          used in {moment(selected).format("MMM yyyy")}
        </Col>
        <Col md={6} className="text-end">
          <DatePicker
            dateFormat="MMM yyyy"
            selected={selected}
            renderMonthContent={renderMonthContent}
            onChange={(date) => setSelected(date)}
            showMonthYearPicker
          />
        </Col>
      </Row>
      <ResponsiveContainer width="100%" className="mt-4" height={500}>
        {userGroupByDate.length ? (
          <BarChart data={userGroupByDate}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip cursor={false} />
            <Bar dataKey="uv" fill="#3e85f0" />
          </BarChart>
        ) : (
          <div className="mt-2">
            {" "}
            <h5 className="text-center mt-4"> No any uses found! </h5>
          </div>
        )}
      </ResponsiveContainer>
      <span className="text-secondary">Data is updated every minute.</span>
    </Default>
  );
};
