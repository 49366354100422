import { Fragment, useRef, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { BillingAddress } from "./BillingAddress";
import { FaCcVisa } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../config/Api_url";
import { PaypalPayment } from "./PaypalPayment";

export const TopUpAccountBalance = ({ show, handleClose }) => {
    const [showBillingAddress, setShowBillingAddress] = useState(false);
    const [changePaymentMethod, setChangePaymentMethod] = useState(false);

    const recaptchaRef = useRef();

    return (
        <Fragment>
            <Offcanvas placement="end" show={show} onHide={handleClose}>

                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Top Up Account Balance</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="font-14">
                        <p>Your account balance is used for paying any Overage charges.</p>
                        <p>
                            Your monthly Plan payment is still taken from your payment card.
                        </p>
                        <p>You may top-up your account balance below.</p>
                        <p>Sales tax will be charged where appropriate.</p>
                    </div>
                    <Form>
                        <Form.Group className="mb-3">
                            <div className="form-item-no-colon">
                                <Form.Label className="fw-bold mb-0">
                                    Choose Top Up Amount
                                </Form.Label>
                                <p className="mb-0 font-12">Minimum top-up $10.</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <Form.Control type="number" style={{ width: 150 }} />
                                <b>$10.00</b>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <div className="form-item-no-colon">
                                <Form.Label className="fw-bold mb-0">
                                    Billing Address
                                </Form.Label>
                            </div>
                            <div className="font-14">
                                <strong className="text-secondary">Calala Group Pty LTd</strong>
                                <div className="d-flex flex-wrap" style={{ width: 250 }}>
                                    <div className="text-secondary">5 Clayton Court, </div>
                                    <div className="text-secondary">Bray Park, </div>
                                    <div className="text-secondary">QLD, </div>
                                    <div className="text-secondary">4500, </div>
                                    <div className="text-secondary">Australia</div>
                                </div>
                                <Button
                                    variant="link"
                                    onClick={() => setShowBillingAddress(true)}
                                    className="text-decoration-none text-black p-0 border-0 font-14"
                                >
                                    Change
                                </Button>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className="form-item-no-colon">
                                <Form.Label className="fw-bold mb-0">Payment Method</Form.Label>
                            </div>
                            {!changePaymentMethod ? (
                                <div className="d-flex font-14">
                                    <div className="pe-2">
                                        <p className="mb-0">
                                            Visa
                                            <span className="text-secondary"> ending in 5052</span>
                                        </p>
                                        <Button
                                            variant="link"
                                            onClick={() => setChangePaymentMethod(true)}
                                            className="text-decoration-none text-secondary p-0 border-0 font-14"
                                        >
                                            Change
                                        </Button>
                                    </div>
                                    <FaCcVisa size={20} />
                                </div>
                            ) : (
                                <PaypalPayment />
                            )}
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
                <div className="p-3">
                    {false && <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} className="mb-3" />}
                    <Button>Purchase</Button>
                </div>
            </Offcanvas>
            <BillingAddress
                show={showBillingAddress}
                handleClose={() => setShowBillingAddress(false)}
            />
        </Fragment>
    );
};
