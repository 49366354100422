import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { Toaster } from "react-toastify";
export const AuthWrapper = ({
  title,
  children,
  buttonText,
  linkText,
  linkTo,
  formik,
  loaderId = "",
}) => {
  const location = useLocation();

  return (
    <Container>
      <Row className="vh-100 justify-content-center">
        {/* <Toaster /> */}

        <ToastContainer />
        <Col style={{ maxWidth: "400px" }}>
          <h1 className="h3 mb-3 fw-bold text-center">{title || "Title"}</h1>
          <Form onSubmit={formik?.handleSubmit}>
            {children}
            <Button
              type="submit"
              id={loaderId}
              disabled={formik?.isSubmitting}
              className="w-100 py-2"
            >
              {buttonText || "Button"}
            </Button>
          </Form>
          {["/sign-in", "/sign-up"].includes(location.pathname) && (
            <p className="mt-3">
              {linkText}{" "}
              <Link to={linkTo}>
                {linkTo?.includes("sign-in") ? "Sign In" : "Sign Up"}
              </Link>
            </p>
          )}
        </Col>
      </Row>
    </Container>
  );
};
