import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { APIRequestFields } from "../../APIRequestFields";
import {
    AiOutlineDownload,
    AiOutlineInfoCircle,
    AiOutlineUpload,
} from "react-icons/ai";

export const AddBulkASIN = ({ show, handleClose }) => {
    return (
        <Offcanvas placement="end" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Bulk ASIN</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p className="font-14">
                    You may add up to 15,000 Requests at once by adding in bulk.
                </p>
                <div className="mb-2">
                    <Button
                        variant="link"
                        className="text-decoration-none p-0 color-inherit font-14"
                    >
                        <AiOutlineDownload className="me-2" />
                        <span>Download template</span>
                    </Button>
                </div>
                <div className="mb-2">
                    <Button
                        variant="link"
                        className="text-decoration-none p-0 color-inherit font-14"
                    >
                        <AiOutlineUpload className="me-2" />
                        <span>Upload populated template</span>
                    </Button>
                </div>
                <Alert variant="info" className="d-flex gap-2">
                    <span>
                        <AiOutlineInfoCircle size={20} className="text-primary me-1" />
                    </span>
                    <div>
                        <div className="font-14">How to use bulk import</div>
                        <p className="font-12">
                            Use a Bulk Import CSV file to import up to 15,000 requests into
                            your Collection at once.
                        </p>
                        <p className="font-12">
                            You can use{" "}
                            <span className="fw-bold">any API request parameter</span> in your
                            Bulk Import CSV. The template gives examples, but you're free to
                            add any API parameter name as another column in the Bulk Import
                            CSV.
                        </p>
                        <p className="font-12">
                            <Button
                                variant="link"
                                className="text-decoration-none p-0 color-inherit font-14"
                            >
                                Download
                            </Button>{" "}
                            an example Bulk Import CSV template file.
                        </p>
                        <p className="font-12 mb-0">
                            Once you've created your Bulk Import CSV you can upload it.
                        </p>
                    </div>
                </Alert>
                <Form>
                    <APIRequestFields isCustomID />
                </Form>
            </Offcanvas.Body>
            <div className="p-3">
                <Button
                    variant="outline-primary"
                    onClick={handleClose}
                    className="me-1"
                >
                    Close
                </Button>
            </div>
        </Offcanvas>
    );
};
