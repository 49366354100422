import PostRequest from "../../services/PostRequest";

export const ASIN_OPEN = "ASIN_OPEN";
export const ASIN_VIEW_DETAILS = "ASIN_VIEW_DETAILS";

export const setAsin = (data = false) => {
  return async (dispatch) => {
    dispatch({
      type: ASIN_OPEN,
      value: data,
    });
  };
};

export const asinView = (data) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/schedules/asin/get/", data);
      dispatch({
        type: ASIN_VIEW_DETAILS,
        value: response,
      });
    } catch (error) {
      console.log("error", error.message);
    }
  };
};
