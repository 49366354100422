import PostRequest from "../../services/PostRequest";

export const COLLECTION_LIST = "COLLECTION_LIST";

export const setCollectionsList = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/schedules/get_schedules/");

      dispatch({
        type: COLLECTION_LIST,
        value: response,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};
