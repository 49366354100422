import { Badge, Button, Form, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import {
  // allowedRequestType,
  apiOptions,
  daysOfMonth,
  daysOfWeek,
  hoursOptions,
  minutesFrequencyOptions,
} from "../../data/constants";
import { ChooseAPI } from "../../common/custom-options";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChooseRequests } from "./ChooseRequests";
import { showToaster } from "../../common/common";
import PostRequest from "../../services/PostRequest";
import { useDispatch } from "react-redux";
import { setCollectionsList } from "../../reducer/collection/Collection.action";

export const AddCollection = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [showRequests, setShowRequests] = useState(false);
  const [collectionId, setCollectionId] = useState(false);

  const handleChange = (event) => setSelectedSchedule(event.target.value);

  const handelCreateCollection = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);

      let scheduleTime = {};

      const baseSchedule = {
        hours: formData.getAll("hours"),
      };

      if (
        selectedSchedule === "Every X Minutes" ||
        selectedSchedule === "Manual"
      ) {
        scheduleTime = {
          minutes_frequency: formData.get("minutes_frequency"),
          days_of_month: formData.getAll("days_of_month"),
          days_of_week: formData.getAll("days_of_week"),
          ...baseSchedule,
        };
      } else if (selectedSchedule === "Weekly") {
        scheduleTime = {
          days_of_week: formData.getAll("days_of_week"),
          ...baseSchedule,
        };
      } else if (selectedSchedule === "Monthly") {
        scheduleTime = {
          days_of_month: formData.getAll("days_of_month"),
          ...baseSchedule,
        };
      } else if (selectedSchedule === "Daily") {
        scheduleTime = baseSchedule;
      }

      var data = {};

      data.schedule_time = JSON.stringify(scheduleTime);

      formData.forEach(function (value, key) {
        if (
          key !== "minutes_frequency" &&
          key !== "days_of_month" &&
          key !== "days_of_week" &&
          key !== "hours"
        )
          data[key] = value;
      });

      data.status = data.status == "on" ? "enable" : "disabled";

      const option = {
        showLoader: "add-new-collection",
      };

      const response = await PostRequest(
        "/schedules/add_schedule/",
        data,
        option
      );
      setCollectionId(response);
      // navigate(`/collections/${response.id}`);
      handleClose();
      setShowRequests(true);
      dispatch(setCollectionsList());
    } catch (error) {
      console.log(error.message);
      showToaster(error.message, "error");
    }
  };

  return (
    <Fragment>
      <Offcanvas
        placement="end"
        style={{ width: "550px" }}
        show={show}
        onHide={() => {
          handleClose();
          setSelectedSchedule(null);
        }}
      >
        <Form onSubmit={handelCreateCollection}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Collection</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{ overflowX: "auto", maxHeight: "calc(100vh - 150px)" }}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Form.Group className="w-75">
                <Form.Label className="font-14">Name</Form.Label>
                <Form.Control required name="name" type="text" />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-14">Enabled</Form.Label>
                <Form.Check name="status" type="switch" />
              </Form.Group>
            </div>
            <Form.Group className="mb-2">
              <Form.Label className="font-14">API</Form.Label>
              <Select
                options={apiOptions}
                name="request_type"
                getOptionLabel={(option) => <ChooseAPI data={option} />}
                menuPlacement="auto"
              />
            </Form.Group>
            {/* <Form.Group className="mb-2">
              <Form.Label className="font-14">Allowed Request Type</Form.Label>
              <Select
                options={allowedRequestType}
                menuPlacement="auto"
                name="request_type"
                required
                getOptionLabel={(option) => <ChooseAPI data={option} />}
              />
              <p className="text-muted font-12 mb-0 mt-1">
                Optionally lock the type of requests that can be added to this
                Collection. When locked the correct CSV fields, appropriate to
                the requests in the Collection, are selected by default.
              </p>
            </Form.Group> */}
            <div className="d-flex align-items-center mb-2 divider__inner-text">
              <span className="pe-1 fw-bold">Schedule</span>
            </div>
            <div className="mb-3 ps-3">
              <Form.Group className="font-14">
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="schedule"
                  label="Every X Minutes"
                  value="Every X Minutes"
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="schedule"
                  label="Daily"
                  value="Daily"
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="schedule"
                  label="Weekly"
                  value="Weekly"
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="schedule"
                  label="Monthly"
                  value="Monthly"
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="schedule"
                  label="Manual"
                  value="Manual"
                  onChange={handleChange}
                />
              </Form.Group>
              {(selectedSchedule === "Every X Minutes" ||
                selectedSchedule === "Manual") && (
                <>
                  <Form.Group className="mb-2">
                    <Form.Label className="font-14">
                      Minutes Frequency
                    </Form.Label>
                    <Select
                      options={minutesFrequencyOptions}
                      placeholder="Select minutes frequency..."
                      name="minutes_frequency"
                      menuPlacement="auto"
                    />
                    <p className="font-12 text-muted mb-0 mt-1">
                      Collections run at the selected frequency 24/7 unless
                      specific hours or days of week/month are selected below.
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="font-14">
                      Days of the Month
                    </Form.Label>
                    <Select
                      isMulti
                      options={daysOfMonth}
                      closeMenuOnSelect={false}
                      name="days_of_month"
                      menuPlacement="auto"
                      placeholder="Select days of month (optional)..."
                    />
                    <p className="font-12 text-muted mb-0 mt-1">
                      Optionally run on just the days of the month selected.
                      Leave empty to not limit to specific days of the month.
                    </p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="font-14">Days of Week</Form.Label>
                    <Select
                      isMulti
                      options={daysOfWeek}
                      name="days_of_week"
                      closeMenuOnSelect={false}
                      menuPlacement="auto"
                      placeholder="Select days of week (optional)..."
                    />
                    <p className="font-12 text-muted mb-0 mt-1">
                      Optionally run on just the days of the week selected.
                      Leave empty to not limit to specific days of the week.
                    </p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="font-14">Hours</Form.Label>
                    <Select
                      isMulti
                      options={hoursOptions}
                      name="hours"
                      closeMenuOnSelect={false}
                      menuPlacement="auto"
                      placeholder="Select hours (optional)..."
                    />
                    <p className="text-muted font-12 mb-0 mt-1">
                      Optionally run during just the hours selected. Leave empty
                      to not limit to specific hours. Hours are evaluated in the
                      timezone selected on your{" "}
                      <Link
                        to="/profile"
                        className="text-decoration-none text-muted"
                      >
                        profile
                      </Link>
                      .
                    </p>
                  </Form.Group>
                </>
              )}
              {selectedSchedule === "Weekly" && (
                <Form.Group>
                  <Form.Label className="font-14">Days of Week</Form.Label>
                  <Select
                    isMulti
                    options={daysOfWeek}
                    name="days_of_week"
                    closeMenuOnSelect={false}
                    menuPlacement="auto"
                    placeholder="Select days of week..."
                  />
                </Form.Group>
              )}
              {selectedSchedule === "Monthly" && (
                <Form.Group>
                  <Form.Label className="font-14">Days of the Month</Form.Label>
                  <Select
                    isMulti
                    options={daysOfMonth}
                    name="days_of_month"
                    closeMenuOnSelect={false}
                    menuPlacement="auto"
                    placeholder="Select days of month..."
                  />
                </Form.Group>
              )}
              {(selectedSchedule === "Daily" ||
                selectedSchedule === "Weekly" ||
                selectedSchedule === "Monthly") && (
                <Form.Group>
                  <Form.Label className="font-14">Hours</Form.Label>
                  <Select
                    isMulti
                    options={hoursOptions}
                    name="hours"
                    closeMenuOnSelect={false}
                    menuPlacement="auto"
                    placeholder="Select hours..."
                  />
                  <p className="text-muted font-12 mb-0 mt-1">
                    Schedules are run in the timezone selected on your{" "}
                    <Link
                      to="/profile"
                      className="text-decoration-none text-muted"
                    >
                      profile
                    </Link>
                    .
                  </p>
                </Form.Group>
              )}
            </div>
            <div className="d-flex align-items-center mb-2 divider__inner-text">
              <span className="pe-1 fw-bold">Priority</span>
            </div>
            <Form.Group className="mb-3 ps-3">
              <div className="font-14">
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="priority"
                  value={"Highest"}
                  label={<Badge bg="danger">Highest</Badge>}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  name="priority"
                  value={"High"}
                  label={<Badge bg="warning">High</Badge>}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  value={"Normal"}
                  name="priority"
                  label={<Badge bg="success">Normal</Badge>}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  value={"Low"}
                  name="priority"
                  label={<Badge bg="info">Low</Badge>}
                />
                <Form.Check
                  inline
                  required
                  type="radio"
                  value={"Lowest"}
                  name="priority"
                  label={<Badge bg="primary">Lowest</Badge>}
                />
              </div>
              <p className="text-muted font-12 mb-0 mt-1">
                When multiple Collections are queued, Rainforest API starts them
                in priority order. Learn more about priorities.
              </p>
            </Form.Group>
            <div className="d-flex align-items-center mb-2 divider__inner-text">
              <span className="me-1 fw-bold">Notifications</span>
            </div>
            <Form.Group className="mb-3 ps-3">
              <Form.Label className="font-14">Send via Email</Form.Label>
              <Form.Control
                type="email"
                name="notification_email"
                placeholder="test@example.com"
              />
              <p className="text-muted font-12 mb-0 mt-1">
                Enter an email to receive notifications, if this is left blank
                then the email address on your account is used instead.
              </p>
            </Form.Group>
            <Form.Group className="ps-3">
              <Form.Label className="font-14 text-muted">
                Destinations - Coming Soon
              </Form.Label>
              <Form.Control
                type="text"
                name="destinations"
                placeholder="Search Destinations..."
              />
              <p className="text-muted font-12 mb-0 mt-1">
                Choose one or more Destinations (i.e. Amazon S3 Buckets, Google
                Cloud Storage buckets, Microsoft Azure Blob Storage or Alibaba
                Cloud OSS Buckets) where Result Sets from this Collection are to
                be sent. Create Destinations on the{" "}
                <span className="text-decoration-none text-muted cursor-pointer">
                  Destinations page
                </span>
                .
              </p>
            </Form.Group>
          </Offcanvas.Body>
          <div className="p-3">
            <Button
              variant="outline-primary"
              onClick={() => {
                handleClose();
                setSelectedSchedule(null);
              }}
              className="me-1"
            >
              Cancel
            </Button>
            <Button type="submit" id="add-new-collection">
              Next
            </Button>
          </div>
        </Form>
      </Offcanvas>

      <ChooseRequests
        show={showRequests}
        handleClose={() => setShowRequests(false)}
        setShowRequests={setShowRequests}
        collectionId={collectionId?.id}
      />
    </Fragment>
  );
};
