import { ZIPCODES_LIST } from "./ZipCode.action";

const intailreducer = {
  zipCodesList: [],
};

export const zipCodeReducer = (state = intailreducer, action) => {
  switch (action.type) {
    case ZIPCODES_LIST:
      return { ...state, zipCodesList: action.value };

    default:
      return state;
  }
};
