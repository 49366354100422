import { COLLECTION_LIST } from "./Collection.action";

const intailreducer = {
  collectionList: [],
};

export const collectionReducer = (state = intailreducer, action) => {
  switch (action.type) {
    case COLLECTION_LIST:
      return { ...state, collectionList: action.value };

    default:
      return state;
  }
};
