import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { Badge, Dropdown, Table } from "react-bootstrap";
import { COLLECTIONS_DATA as data } from "../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { setCollectionsList } from "../../reducer/collection/Collection.action";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../common/common";
import { AiOutlineDelete } from "react-icons/ai";
import { CollectionLoader } from "../../loader/Loader";
import { useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { asinView, setAsin } from "../../reducer/default/default.action";

export const CollectionsTable = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collectionList = useSelector(
    (item) => item.collectionReducer.collectionList
  );

  const [loader, setLoader] = useState(false);
  const handelDeleteCollection = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete collection?"))
        return false;

      const data = {
        schedule_id: id,
      };
      await PostRequest("/schedules/delete_schedule/", data);
      showToaster("Collection deleted successfully.");
      // navigate("/collections");
      dispatch(setCollectionsList());
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const handelOpenAsin = (id) => {
    const data = {
      collection: id,
    };
    dispatch(asinView(data));
    dispatch(setAsin(true));
    // var timer = setTimeout(() => {
    //   dispatch(setAsin(true));
    // }, 300);
    // return () => clearTimeout(timer);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "record_count",
      header: "Record Count",
    },
    {
      accessorKey: "schedule_status",
      header: "Status",
      cell: (info) => (
        <Badge
          bg={
            info.getValue() === "Completed"
              ? "success"
              : info.getValue() === "Processing" ||
                info.getValue() === "Pending"
              ? "info"
              : "warning"
          }
        >
          {info.getValue()}
        </Badge>
      ),
    },
    {
      accessorKey: "schedule",
      header: "Recurring",
    },
    {
      accessorKey: "created",
      header: "Created",
      cell: (info) => (
        <span>{moment(info.getValue()).format("DD MMM yyyy")}</span>
      ),
    },
    {
      accessorKey: "completed",
      header: "Completed",
      cell: (info) =>
        info.getValue() && (
          <span>{moment(info.getValue()).format("DD MMM yyyy")}</span>
        ),
    },
    {
      id: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="cursor-pointer text-decoration-none"
            variant="success"
            id="dropdown-basic"
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => handelDeleteCollection(row.original.id)}
            >
              <AiOutlineDelete size={19} className="mb-1" /> Delete Collection
            </Dropdown.Item>

            <Dropdown.Item onClick={() => handelOpenAsin(row.original.id)}>
              <FiEye size={18} className="mb-1 me-1" /> Asin View
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: collectionList,
    getCoreRowModel: getCoreRowModel(),
  });

  const loadCollections = async () => {
    try {
      setLoader(true);
      await dispatch(setCollectionsList());
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadCollections();
  }, []);

  return (
    <Table responsive>
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {loader ? (
          <CollectionLoader />
        ) : (
          <Fragment>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </Fragment>
        )}

        {loader ? (
          <CollectionLoader />
        ) : (
          <Fragment>
            {collectionList.length == "0" && (
              <tr>
                <td
                  colSpan={tableInstance.getAllColumns().length}
                  style={{ border: "none" }}
                  className="text-center"
                >
                  <h6 className="mt-4">No any collections.</h6>
                </td>
              </tr>
            )}
          </Fragment>
        )}
      </tbody>
    </Table>
  );
};
