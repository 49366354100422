import { Button, Card, Form } from "react-bootstrap";

export const ProfileWrapper = ({
  header,
  children,
  buttonText,
  buttonType = "submit",
  onButtonClick,
  handleSubmit,
  isFooter = true,
  loaderId = "",
}) => {
  return (
    <Card>
      <Card.Header>{header}</Card.Header>
      <Form onSubmit={buttonType == "submit" ? handleSubmit : null}>
        <Card.Body>{children}</Card.Body>
        {isFooter && (
          <Card.Footer>
            <Button id={loaderId} type={buttonType} onClick={onButtonClick}>
              {buttonText || "Save"}
            </Button>
          </Card.Footer>
        )}
      </Form>
    </Card>
  );
};
