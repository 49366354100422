import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { CountryOption } from "../../common/custom-options";

export const BillingAddress = ({ show, handleClose }) => {
  const countryOptions = useMemo(() => countryList().getData(), []);

  return (
    <Offcanvas placement="end" show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Billing Address</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p className="font-14">
          Your Billing Address should be the address that your payment method is
          registered to.
        </p>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="font-14" column sm="4">
              Business Name
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="font-14" column sm="4">
              Address 1
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="font-14" column sm="4">
              Address 2
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="font-14" column sm="4">
              City
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="font-14" column sm="4">
              State
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="font-14" column sm="4">
              Postal Code
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label className="font-14" column sm="4">
              Country
            </Form.Label>
            <Col sm="8">
              <Select
                options={countryOptions}
                getOptionLabel={(option) => <CountryOption data={option} />}
                menuPlacement="auto"
              />
            </Col>
          </Form.Group>
        </Form>
      </Offcanvas.Body>
      <div className="p-3">
        <Button className="me-1">Save</Button>
        <Button onClick={handleClose} variant="outline-primary">Cancel</Button>
      </div>
    </Offcanvas>
  );
};
