import { Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export const ProfileDropdown = ({ className, children }) => {
  const navigate = useNavigate();

  const AUTH = JSON.parse(window.localStorage.getItem('AUTH'))

  const handleSignOut = () => {
    window.localStorage.clear()
    navigate("/sign-in");
  }

  return (
    <Dropdown className={`${className}`}>
      {children || (
        <div
          className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle cursor-pointer"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://github.com/mdo.png"
            alt=""
            width={32}
            height={32}
            className="rounded-circle me-2"
          />
          <strong>{AUTH ? AUTH.name : 'mdo'}</strong>
        </div>
      )}
      <ul className="dropdown-menu text-small shadow">
        <li>
          <Link to="/account" className="dropdown-item">
            Account
          </Link>
        </li>
        <li>
          <Link to="/profile" className="dropdown-item">
            Profile
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <Button onClick={handleSignOut} className="dropdown-item">
            Sign out
          </Button>
        </li>
      </ul>
    </Dropdown>
  );
};
