import PostRequest from "../../services/PostRequest";
export const PLAN_PAYMENT = "PLAN_PAYMENT";
export const USER_SUBSCRIPTION = "USER_SUBSCRIPTION";

export const planPayments = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/plans/get_plans/");
      dispatch({
        type: PLAN_PAYMENT,
        value: response,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const setUserSubscription = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/users/subscription/");
      dispatch({
        type: USER_SUBSCRIPTION,
        value: response,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};
