import { Button, Tab, Nav } from 'react-bootstrap'
import { AiOutlineCode, AiOutlineDesktop } from 'react-icons/ai';
import { CodeSample } from './CodeSample';

export const TabList = () => {
    return (
        <Tab.Container defaultActiveKey="codeSample">
            <Nav
                variant='tabs'
                className="mb-3 nav-underline playground__tabs fw-bold"
            >
                <Nav.Item>
                    <Nav.Link eventKey="codeSample" as='button'>
                        <AiOutlineCode />
                        <span className='ms-1'>Code Sample</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className='me-auto'>
                    <Nav.Link eventKey="results" as='button'>
                        <AiOutlineDesktop />
                        <span className='ms-1'>Results</span>
                    </Nav.Link>
                </Nav.Item>
                <div>
                    <Button>Send API Request</Button>
                </div>
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="codeSample">
                    <CodeSample />
                </Tab.Pane>
                <Tab.Pane eventKey="results" className='text-center'>
                    <Button>Send API Request</Button>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    )
}
