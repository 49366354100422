import { Alert, Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import {
  AiOutlineAmazon,
  AiOutlineCode,
  AiOutlineDesktop,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { APIRequestForm } from "../../components/playground/APIRequestForm";
import { TabList } from "../../components/playground/TabList";
import Select from "react-select";
import { ChooseAPI } from "../../common/custom-options";
import { Default } from "../../components/default/Default";
import "./playground.css";
import { CodeSample } from "../../components/playground/CodeSample";
import { useState } from "react";

const apiOptions = [
  {
    value: "amazon-data",
    label: "Amazon API",
    endpoint: "/amazon",
    icon: AiOutlineAmazon,
  }
];

export const Playground = () => {
  const [show, setShow] = useState(true);

  return (
    <Default>
      {show && (
        <Alert
          variant="info"
          className="d-flex gap-2"
          onClose={() => setShow(false)}
          dismissible
        >
          <span>
            <AiOutlineInfoCircle size={20} className="text-primary me-1" />
          </span>
          <div>
            <div>Welcome to the API Playground</div>
            <span className="font-14">
              Experiment with MultiCart API and extract code samples in
              your preferred language. See the{" "}
              <a
                href="https://www.google.com"
                target="_blank"
                rel="noreferrer noopener"
                className="text-decoration-none color-inherit"
              >
                API Docs
              </a>{" "}
              for more examples and Getting Started guides.
            </span>
          </div>
        </Alert>
      )}

      <div className="d-block d-xl-none">
        <Tabs defaultActiveKey="request">
          <Tab
            eventKey="request"
            title={
              <>
                <AiOutlineCode />
                <span className="ms-1">Request</span>
              </>
            }
            className="pt-3"
          >
            <Form.Group as={Row}>
              <Form.Label className="fw-bold" column sm="4">
                Choose API
              </Form.Label>
              <Col sm="8">
                <Select
                  options={apiOptions}
                  getOptionLabel={(option) => <ChooseAPI data={option} />}
                  menuPlacement="auto"
                />
              </Col>
            </Form.Group>
            <hr />
            <APIRequestForm />
          </Tab>
          <Tab
            eventKey="code"
            title={
              <>
                <AiOutlineCode />
                <span className="ms-1">Code</span>
              </>
            }
            className="pt-3"
          >
            <CodeSample />
          </Tab>
          <Tab
            eventKey="results"
            title={
              <>
                <AiOutlineDesktop />
                <span className="ms-1">Results</span>
              </>
            }
            className="pt-3 text-center"
          >
            <Button>Send API Request</Button>
          </Tab>
        </Tabs>
      </div>

      <Row className="gy-3 d-none d-xl-flex">
        <Col sm={5}>
          <Form.Group as={Row}>
            <Form.Label className="fw-bold" column sm="4">
              Choose API
            </Form.Label>
            <Col sm="8">
              <Select
                options={apiOptions}
                getOptionLabel={(option) => <ChooseAPI data={option} />}
                menuPlacement="auto"
              />
            </Col>
          </Form.Group>
          <hr />
          <APIRequestForm />
        </Col>
        <Col sm={7}>
          <TabList />
        </Col>
      </Row>
    </Default>
  );
};
