import { MobileSidebar } from "../sidebar/MobileSidebar";
import { Sidebar } from "../sidebar/Sidebar";
import { Container } from "react-bootstrap";
import Breadcrumbs from "../Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Default = (props) => {
    const navigate = useNavigate()

    useEffect(() => {
        const TOKEN = window.localStorage.getItem('TOKEN')
        if (!TOKEN) navigate('/sign-in')
    }, [navigate])
    return (
        <div className="d-block d-md-flex vh-100">
            <MobileSidebar />
            <Sidebar />
            <Container className="overflow-y-auto">
                <Breadcrumbs />
                {props.children}
            </Container>
        </div>
    );
};
