import { useFormik } from "formik";
import { FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthWrapper } from "../../../components/authentication/AuthWrapper";
import PostRequest from "../../../services/PostRequest";
import { showToaster } from "../../../common/common";

export const SignUp = () => {
  const navigate = useNavigate();

  const handleSignUp = async (data) => {
    // navigate("/");

    try {
      const option = {
        showLoader: "register-id",
        auth: false,
      };

      await PostRequest("/users/register", data, option);

      showToaster("Your account has been created successfully!", "success");
      navigate("/sign-in");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = "Name is Required";

    if (!values.email) {
      errors.email = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Password Required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password Required";
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "Passwords must match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: handleSignUp,
  });

  return (
    <AuthWrapper
      title="Sign Up"
      formik={formik}
      buttonText="Sign Up"
      linkText={`Already have an account?`}
      linkTo="/sign-in"
      loaderId="register-id"
    >
      <FloatingLabel label="Name" className="mb-3">
        <Form.Control
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder=""
          required
        />
        {formik.touched.name && formik.errors.name && (
          <span className="text-danger font-14">{formik.errors.name}</span>
        )}
      </FloatingLabel>
      <FloatingLabel label="Email address" className="mb-3">
        <Form.Control
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder=""
          required
        />
        {formik.touched.email && formik.errors.email && (
          <span className="text-danger font-14">{formik.errors.email}</span>
        )}
      </FloatingLabel>
      <FloatingLabel className="mb-3" label="Password">
        <Form.Control
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder=""
          required
        />
        {formik.touched.password && formik.errors.password && (
          <span className="text-danger font-14">{formik.errors.password}</span>
        )}
      </FloatingLabel>
      <FloatingLabel className="mb-3" label="Confirm Password">
        <Form.Control
          type="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder=""
          required
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <span className="text-danger font-14">
            {formik.errors.confirmPassword}
          </span>
        )}
      </FloatingLabel>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="I accept the Terms of use & Privacy Policy"
          required
        />
      </Form.Group>
    </AuthWrapper>
  );
};
