import { Col, Row } from "react-bootstrap";
import { IoCartOutline } from "react-icons/io5";
import { ProfileWrapper } from "../../components/profile/Wrapper";
import { PlanAndUsage } from "../../components/plan-payment/PlanAndUsage";
import { PaymentDetails } from "../../components/plan-payment/PaymentDetails";
import { Default } from "../../components/default/Default";

import "./plan-payment.css";
import { useState } from "react";
import { ChangePlan } from "../../components/plan-payment/Modal";

export const PlanAndPayment = () => {
  const [show, setShow] = useState(false);

  return (
    <Default>
      <Row className="gy-4">
        <Col md={7}>
          <ProfileWrapper
            header="Plan & Usage"
            buttonType="button"
            onButtonClick={() => setShow(true)}
            buttonText={
              <>
                <IoCartOutline />
                <span className="ms-1">Change Plan</span>
              </>
            }
          >
            <PlanAndUsage />
          </ProfileWrapper>
        </Col>
        <Col md={5}>
          <ProfileWrapper header="Payment Details" isFooter={false}>
            <PaymentDetails />
          </ProfileWrapper>
        </Col>
      </Row>
      <ChangePlan show={show} handleClose={() => setShow(false)} />
    </Default>
  );
};
