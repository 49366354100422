import React from "react";

const ErrorMessage = ({ errors = {} }) => {
  return (
    //     <div className="bg-danger text-white">
    <div className="">
      <p className="text-danger text-bold">Oops!</p>
      <ul>
        {Object.keys(errors)?.map((eKey, index) => {
          return (
            <li key={index} className="text-danger">
              {eKey} : {errors[eKey].join(", ")}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ErrorMessage;
