import { PLAN_PAYMENT, USER_SUBSCRIPTION } from "./planPayment.action";

const intailreducer = {
  planPayment: [],
  userSubscription: {},
};

export const planPaymentReducer = (state = intailreducer, action) => {
  switch (action.type) {
    case PLAN_PAYMENT:
      return { ...state, planPayment: action.value };

    case USER_SUBSCRIPTION:
      return { ...state, userSubscription: action.value };
    default:
      return state;
  }
};
