import { Button, Form } from "react-bootstrap";
import { APIRequestFields } from "../APIRequestFields";

export const APIRequestForm = () => {
  return (
    <Form>
      <APIRequestFields />
      <div className="text-center mb-3">
        <Button>Send API Request</Button>
      </div>
    </Form>
  );
};
