import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { AddSingleASIN } from "./requests/AddSingleASIN";
import { AddBulkASIN } from "./requests/AddBulkASIN";
import { useDispatch, useSelector } from "react-redux";
import { setAsin } from "../../reducer/default/default.action";

export const ChooseRequests = ({
  show,
  handleClose,
  setShowRequests,
  collectionId,
}) => {
  const dispatch = useDispatch();
  const _asinBar = useSelector((item) => item.defaultReducer.asinBar);

  //   const [showSingleASIN, setShowSingleASIN] = useState(false);
  const [showBulkASIN, setShowBulkASIN] = useState(false);

  const handelOpenAsin = () => {
    dispatch(setAsin(true));
  };

  const handleCloseSin = () => {
    dispatch(setAsin(false));
  };
  return (
    <>
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Choose Requests</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
            {/* <Button onClick={() => setShowSingleASIN(true)}> */}
            <Button onClick={handelOpenAsin}>Add Single ASIN</Button>
            <Button
              variant="outline-primary"
              onClick={() => setShowBulkASIN(true)}
            >
              Add Bulk ASIN
            </Button>
          </div>
        </Offcanvas.Body>
        <div className="p-3">
          <Button
            variant="outline-primary"
            onClick={handleClose}
            // className="me-1"
          >
            Cancel
          </Button>
          {/* <Button>Next</Button> */}
        </div>
      </Offcanvas>
      <AddSingleASIN
        // show={showSingleASIN}
        show={_asinBar}
        // handleClose={() => setShowSingleASIN(false)}
        handleClose={handleCloseSin}
        setShowRequests={setShowRequests}
        collectionId={collectionId}
      />
      <AddBulkASIN
        show={showBulkASIN}
        handleClose={() => setShowBulkASIN(false)}
      />
    </>
  );
};
