// Breadcrumbs.js
import { Breadcrumb, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useHeader } from '../contexts/HeaderContext';
import { COLLECTIONS_DATA as data } from '../data/constants'

const BREADCRUMBS = {
    'playground': 'API Playground',
    'postcodes': 'Postcodes / Zipcodes',
}

const Breadcrumbs = () => {
    const { handleShowCollection } = useHeader()

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    var actions;

    if (location.pathname === '/collections' && data?.length > 0) {
        actions = <Button onClick={handleShowCollection}>Add Collection</Button>
    }

    var breadcrumbs = <Breadcrumb className='mt-3'>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
        </Breadcrumb.Item>
        {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLastItem = index === pathnames.length - 1;
            const displayName = BREADCRUMBS[value] || value.charAt(0).toUpperCase() + value.slice(1).replace(/-/g, ' ');

            return isLastItem ? (
                <Breadcrumb.Item active key={to}>
                    {displayName}
                </Breadcrumb.Item>
            ) : (
                <Breadcrumb.Item linkAs={Link} linkProps={{ to }} key={to}>
                    {displayName}
                </Breadcrumb.Item>
            );
        })}
    </Breadcrumb>

    if (!actions) return breadcrumbs

    return (
        <div className={`d-flex justify-content-between align-items-center `}>
            {breadcrumbs}
            {actions}
        </div>
    );
};

export default Breadcrumbs;
