import { Link, useNavigate } from "react-router-dom";
import { FloatingLabel, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { AuthWrapper } from "../../../components/authentication/AuthWrapper";
import { showToaster } from "../../../common/common";
import PostRequest from "../../../services/PostRequest";

export const SignIn = () => {
  const navigate = useNavigate();

  const handleSignIn = async (data) => {
    try {
      const option = {
        showLoader: "login-id",
        auth: false,
      };

      const response = await PostRequest("/users/login", data, option);

      showToaster("welcome back!", "success");

      window.localStorage.setItem("TOKEN", response.token);
      window.localStorage.setItem("AUTH", JSON.stringify(response.user));

      navigate("/playground");
    } catch (error) {
      console.log("error", error.message);

      showToaster(error.message, "error");
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) errors.password = "Password Required";
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: handleSignIn,
  });

  return (
    <AuthWrapper
      title="Sign In"
      formik={formik}
      buttonText="Sign In"
      linkText={`Don't have an account?`}
      linkTo="/sign-up"
      loaderId="login-id"
    >
      <FloatingLabel label="Email address" className="mb-3">
        <Form.Control
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          required
          placeholder=""
        />
        {formik.touched.email && formik.errors.email && (
          <span className="text-danger font-14">{formik.errors.email}</span>
        )}
      </FloatingLabel>
      <FloatingLabel className="mb-3" label="Password">
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          required
        />
        {formik.touched.password && formik.errors.password && (
          <span className="text-danger font-14">{formik.errors.password}</span>
        )}
      </FloatingLabel>
      <div className="d-flex justify-content-between mb-3">
        <Form.Group>
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group>
        <Link to="/password/forgot">Forgot Password</Link>
      </div>
    </AuthWrapper>
  );
};
