import { toast } from "react-toastify";
import PostRequest from "../services/PostRequest";
import ErrorMessage from "../components/error-messaage/ErrorMessage";

export const handleCopy = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  } catch (error) {
    toast.error("Failed to Copy!");
  }
};

// import toast, { Toaster } from "react-hot-toast";

export const showToaster = (message, type = "success") => {
  if (type == "success") {
    toast.success(message, {
      duration: 1000,
      position: "top-right",
    });
  } else {
    try {
      const errors = JSON.parse(message);
      toast.error(<ErrorMessage errors={errors} />, {
        duration: 5000,
      });
    } catch (error) {
      toast.error(message, {
        duration: 1000,
        position: "top-right",
      });
    }
  }
};

export const handlePapalPaymentInit = async (
  data,
  url = "/plans/payment/init"
) => {
  try {
    const response = await PostRequest(url, data);
    if (!response)
      throw new Error("Oops! failed to make payment! Please try again");

    var token = "";
    var links = response.links;
    for (var i = 0; i < links.length; i++) {
      if (links[i].rel === "approval_url") {
        token = links[i].href.split("EC-", 2)[1];
      }
    }
    return token;
  } catch (error) {
    showToaster(error.message, "error");
    return false;
  }
};

export const handlePaypalPaymentApproved = async (
  data,
  url = "/plans/payment/execute"
) => {
  try {
    const response = await PostRequest(url, data);

    return response;
  } catch (error) {
  } finally {
  }
};

export const keyGenrat = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
};

export const GenerateKey = (length) => {
  return Math.random().toString(36).substring(2);
};
