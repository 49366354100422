import { Button, Form, Offcanvas } from "react-bootstrap";
import { APIRequestFields } from "../../APIRequestFields";
import { useNavigate, useParams } from "react-router-dom";
import PostRequest from "../../../services/PostRequest";
import { showToaster } from "../../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { setCollectionsList } from "../../../reducer/collection/Collection.action";
// import { useHeader } from "../../../contexts/HeaderContext";

export const AddSingleASIN = ({
  show,
  handleClose,
  setShowRequests,
  collectionId,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _asinDetails = useSelector((item) => item.defaultReducer.asinDetails);

  const handleAddSingleASIN = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);

      var data = {};

      formData.forEach((value, key) => {
        data[key] = value;
      });

      // data.collection = params.id;
      data.collection = collectionId;

      const option = {
        showLoader: "add_signal_asin",
      };

      await PostRequest("/schedules/asin/create/", data, option);

      showToaster("Single asin add successfully.");
      handleClose();
      setShowRequests(false);
      // navigate("/collections");
      // dispatch(setCollectionsList());
    } catch (error) {
      console.log(error.message);
      showToaster(error.message, "error");
    }
  };

  return (
    <Offcanvas placement="end" show={show} onHide={handleClose}>
      <Form onSubmit={handleAddSingleASIN}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Single ASIN</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{ overflowX: "auto", height: "calc(100vh - 132px)" }}
        >
          <APIRequestFields isCustomID _asinDetails={_asinDetails} />
        </Offcanvas.Body>
        <div className="p-3">
          <Button
            variant="outline-primary"
            onClick={handleClose}
            className="me-1"
          >
            Cancel
          </Button>
          <Button id="add_signal_asin" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </Offcanvas>
  );
};
