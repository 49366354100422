import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { IoHelpCircleOutline } from "react-icons/io5";
import { expireOptions, timeZoneOptions } from "../../data/constants";
import { useEffect, useState } from "react";

export const Details = ({ errors, accountDetails }) => {
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  useEffect(() => {
    if (!expireOptions) return;
    const expiry = expireOptions.find(
      (a) => a.value === accountDetails?.unused_collection_expired
    );
    setSelectedExpiry(expiry);
  }, [accountDetails]);

  useEffect(() => {
    if (!timeZoneOptions) return;
    const timezone = timeZoneOptions.find(
      (a) => a.value === accountDetails?.timezone
    );
    setSelectedTimezone(timezone);
  }, [accountDetails]);

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>
          <span>Unused Collections Expire After</span>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Collections that have not been run for the defined period are
                automatically deleted. If you use a high number of Collections,
                you may wish to have a shorter expiry time to keep your account
                under the concurrent Collections limit.
              </Tooltip>
            }
          >
            <span>
              <IoHelpCircleOutline size={20} className="cursor-pointer" />
            </span>
          </OverlayTrigger>
        </Form.Label>
        <Select
          name="unused_collection_expired"
          options={expireOptions}
          menuPlacement="auto"
          required
          value={selectedExpiry}
          onChange={setSelectedExpiry}
        />
        {errors.unused && (
          <span className="font-12 text-danger">{errors.unused}</span>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <span>Timezone</span>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Rainforest API runs scheduled Collections in the timezone you
                choose here.
              </Tooltip>
            }
          >
            <span>
              <IoHelpCircleOutline size={20} className="cursor-pointer" />
            </span>
          </OverlayTrigger>
        </Form.Label>
        <Select
          name="timezone"
          options={timeZoneOptions}
          menuPlacement="auto"
          required
          value={selectedTimezone}
          onChange={setSelectedTimezone}
        />
        {errors.timezone && (
          <span className="font-12 text-danger">{errors.timezone}</span>
        )}
      </Form.Group>
    </>
  );
};
