import { Col, Row } from "react-bootstrap";
import { ProfileWrapper } from "../../components/profile/Wrapper";
import { AboutYou } from "../../components/profile/AboutYou";
import { ChangePassword } from "../../components/profile/ChangePassword";
import { Details } from "../../components/profile/Details";
import { useEffect, useState } from "react";
import { showToaster } from "../../common/common";
import PostRequest from "../../services/PostRequest";
import { Default } from "../../components/default/Default";

export const Profile = () => {
  const [errors, setErrors] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [accountDetails, setAccountDetails] = useState({});
  const handelAboutUserUpdate = async (e) => {
    try {
      e.preventDefault();
      const data = {};
      const formData = new FormData(e.target);
      formData.forEach((value, key) => {
        data[key] = value;
      });

      data["system_emails"] = e.target["system_emails"].checked;
      data["update_emails"] = e.target["update_emails"].checked;
      data["notification_emails"] = e.target["notification_emails"].checked;

      const { name, email } = data;

      setErrors({});
      const newErrors = {};

      if (!name) newErrors.name = "Name is required!";
      if (!email) {
        newErrors.email = "Email is required!";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        newErrors.email = "Email is invalid!";
      }

      if (Object.keys(newErrors).length > 0) return setErrors(newErrors);

      const option = {
        showLoader: "about-you-id",
      };

      const response = await PostRequest("/users/profile/update", data, option);

      console.log("response", response);

      showToaster("Your profile updated successfully!", "success");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const handelUpdateDetails = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries());
      const { unused_collection_expired, timezone } = data;

      setErrors({});
      const newErrors = {};

      if (!unused_collection_expired)
        newErrors.unused_collection_expired =
          "Unused Collections Expire After is required!";
      if (!timezone) newErrors.timezone = "Timezone is required!";

      if (Object.keys(newErrors).length > 0) return setErrors(newErrors);

      const option = {
        showLoader: "details-id",
      };

      await PostRequest("/users/profile/update", data, option);
      showToaster("updated successfully.", "success");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const handleChangePassword = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries());
      const { old_password, new_password } = data;

      setErrors({});
      const newErrors = {};

      if (!old_password)
        newErrors.old_password = "Current Password is required!";

      if (!new_password) {
        newErrors.new_password = "New Password is required!";
      } else if (new_password.length < 8) {
        newErrors.new_password =
          "New Password must be at least 8 characters long.";
      }
      if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
      // console.log("Change Password Form Data:", data);

      const option = {
        showLoader: "changPassword-id",
      };

      await PostRequest("/users/password/change", data, option);

      e.target.reset();

      showToaster("Password has been updated.", "success");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const loadProfile = async () => {
    try {
      const response = await PostRequest("/users/profile");
      setUserProfile(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const loadAccountDetails = async () => {
    try {
      const response = await PostRequest("/users/account-details");
      setAccountDetails(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    loadProfile();
    loadAccountDetails();
  }, []);

  return (
    <Default>
      <Row className="gy-4 mb-3">
        <Col lg={12}>
          <ProfileWrapper
            loaderId="about-you-id"
            header="About You"
            handleSubmit={handelAboutUserUpdate}
          >
            <AboutYou
              userProfile={userProfile}
              errors={errors}
              accountDetails={accountDetails}
              setErrors={setErrors}
            />
          </ProfileWrapper>
        </Col>
        <Col lg={6}>
          <ProfileWrapper
            loaderId="details-id"
            header="Details"
            handleSubmit={handelUpdateDetails}
          >
            <Details
              key={accountDetails.id}
              accountDetails={accountDetails}
              errors={errors}
            />
          </ProfileWrapper>
        </Col>
        <Col lg={6}>
          <ProfileWrapper
            header="Change Password"
            buttonText="Change Password"
            handleSubmit={handleChangePassword}
            loaderId="changPassword-id"
          >
            <ChangePassword errors={errors} setErrors={setErrors} />
          </ProfileWrapper>
        </Col>
      </Row>
    </Default>
  );
};
