import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const LanguageMarkup = ({ language, value }) => {
    return (
        <SyntaxHighlighter language={language} style={a11yDark}>
            {value}
        </SyntaxHighlighter>
    )
}
