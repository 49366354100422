import { Button } from "react-bootstrap";
import { Default } from "../../components/default/Default";
import "./coming-soon.css";

export const ComingSoon = () => {
    return (
        <Default>
            <div className="d-flex flex-column align-items-center justify-content-center main-content">
                <h4>Coming Soon</h4>
                <p className="mb-0 w-50 text-center">
                    Our website is currently undergoing scheduled maintenance. We Should
                    be back shortly. Thank you for your patience.
                </p>
                <Button variant="outline-dark" className="mt-3">NOTIFY US</Button>
            </div>
        </Default>
    );
};
